import { default as completed_45shipmentsMbQlR6isrfMeta } from "/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/completed-shipments.vue?macro=true";
import { default as indexx0kOC2rqrGMeta } from "/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/index.vue?macro=true";
import { default as inventoryDqfhW0nxQWMeta } from "/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/inventory.vue?macro=true";
import { default as loginwLo33fH1FhMeta } from "/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/login.vue?macro=true";
import { default as shipments1EvtxCiv0NMeta } from "/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/shipments.vue?macro=true";
export default [
  {
    name: completed_45shipmentsMbQlR6isrfMeta?.name ?? "completed-shipments",
    path: completed_45shipmentsMbQlR6isrfMeta?.path ?? "/completed-shipments",
    meta: completed_45shipmentsMbQlR6isrfMeta || {},
    alias: completed_45shipmentsMbQlR6isrfMeta?.alias || [],
    redirect: completed_45shipmentsMbQlR6isrfMeta?.redirect,
    component: () => import("/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/completed-shipments.vue").then(m => m.default || m)
  },
  {
    name: indexx0kOC2rqrGMeta?.name ?? "index",
    path: indexx0kOC2rqrGMeta?.path ?? "/",
    meta: indexx0kOC2rqrGMeta || {},
    alias: indexx0kOC2rqrGMeta?.alias || [],
    redirect: indexx0kOC2rqrGMeta?.redirect,
    component: () => import("/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inventoryDqfhW0nxQWMeta?.name ?? "inventory",
    path: inventoryDqfhW0nxQWMeta?.path ?? "/inventory",
    meta: inventoryDqfhW0nxQWMeta || {},
    alias: inventoryDqfhW0nxQWMeta?.alias || [],
    redirect: inventoryDqfhW0nxQWMeta?.redirect,
    component: () => import("/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/inventory.vue").then(m => m.default || m)
  },
  {
    name: loginwLo33fH1FhMeta?.name ?? "login",
    path: loginwLo33fH1FhMeta?.path ?? "/login",
    meta: loginwLo33fH1FhMeta || {},
    alias: loginwLo33fH1FhMeta?.alias || [],
    redirect: loginwLo33fH1FhMeta?.redirect,
    component: () => import("/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/login.vue").then(m => m.default || m)
  },
  {
    name: shipments1EvtxCiv0NMeta?.name ?? "shipments",
    path: shipments1EvtxCiv0NMeta?.path ?? "/shipments",
    meta: shipments1EvtxCiv0NMeta || {},
    alias: shipments1EvtxCiv0NMeta?.alias || [],
    redirect: shipments1EvtxCiv0NMeta?.redirect,
    component: () => import("/home/forge/ufudu.sonnenglas.net/releases/20250309174526/pages/shipments.vue").then(m => m.default || m)
  }
]